<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <div class="d-flex align-items-center">
                    <feather-icon
                        size="24"
                        class="mr-1"
                        icon="GitMergeIcon"
                    />
                    <b-card-title class="ml-25">
                        Locations Hierarchy
                    </b-card-title>
                </div>
                <!-- <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                /> -->
            </b-card-header>

            <b-card-body>
                <b-row>
                    <b-col cols="12">
                        <validation-observer #default="{ invalid }">
                            <b-form ref="form" @submit.stop.prevent="handleCreate">
                                <b-row>
                                    <b-col cols="12" md="2" v-if="selections.length > 0">
                                        <b-form-group label-for="level_id" label="Under">
                                            <validation-provider #default="{ errors }" name="level_id" rules="">
                                                <b-form-select
                                                    id="level_id"
                                                    name="level_id"
                                                    size="sm"
                                                    :state="errors.length > 0 ? false : null"
                                                    v-model="level.level_id"
                                                    disabled
                                                >
                                                    <b-form-select-option
                                                        v-for="selected in selections"
                                                       :value="selected.id"
                                                       :key="selected.id"
                                                    >
                                                        {{ selected.label }}
                                                    </b-form-select-option>
                                                </b-form-select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.level_id">{{serverErrors.level_id[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="2">
                                        <b-form-group label-for="name" label="Level Name">
                                            <validation-provider #default="{ errors }" name="name" rules="">
                                                <b-form-input
                                                    id="name"
                                                    name="name"
                                                    placeholder="Name"
                                                    size="sm"
                                                    :state="errors.length > 0 ? false : null"
                                                    v-model="level.name"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.level_id">{{serverErrors.level_id[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="8">
                                        <b-form-group label-for="coded" label="With Codes">
                                            <validation-provider #default="{ errors }" name="coded" rules="">
                                                <b-form-checkbox
                                                    id="coded"
                                                    name="coded"
                                                    :value="true"
                                                    v-model="level.coded"
                                                    class="custom-control-success ml-2"
                                                    style="margin-top:7px;"
                                                >
                                                </b-form-checkbox>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.coded">{{serverErrors.coded[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <hr />
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>

                <!-- timeline -->
                <b-row>
                    <b-col cols="12">
                        <app-timeline>
                            <app-timeline-item
                                v-for="selected in selections"
                                :variant="variants[Math.floor(Math.random() * (variants.length))]"
                                :key="selected.id"
                            >
                                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                    <h5>{{ selected.label }}</h5>
                                    <small class="timeline-item-time text-nowrap text-muted ml-1">
                                        {12 min ago}
                                    </small>
                                </div>
                                <p v-if="selected.coded">When registering {{ selected.level }}, code must be provided.</p>
                            </app-timeline-item>
                        </app-timeline>
                    </b-col>
                </b-row>
                <!--/ timeline -->
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
    import {
        BRow, BCol,
        BTab, BTabs,
        BCard, BCardHeader, BCardBody, BCardTitle, BCardText,
        BForm, BFormGroup, BFormCheckbox, BFormInput, BFormSelect, BFormSelectOption, BButton, BSpinner,
    } from 'bootstrap-vue'
    import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
    import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
    import vSelect from 'vue-select'
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import { required } from '@validations'
    import store from '@/store'
    import { ref, onUnmounted, onMounted, watch } from '@vue/composition-api'
    import levelsStoreModule from '@/views/cromis/levels/levelsStoreModule'
    export default {
        props: {},
        components: {
            BRow, BCol,
            BCard, BCardHeader, BCardBody, BCardTitle, BCardText,
            BTab, BTabs,
            BForm, BFormGroup, BFormCheckbox, BFormInput, BFormSelect, BFormSelectOption, BButton, BSpinner, vSelect,
            AppTimeline, AppTimelineItem,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context) {
            const saving = ref(null)
            const serverErrors = ref(null)
            const selections = ref([])
            const levels = ref(null)
            const loading = ref(false)

            const variants = ref([
                'primary',
                'secondary',
                'success',
                'danger',
                'warning',
                'info',
                'dark'
            ])

            const level = ref({
                id: null,
                name: null,
                level_id: null,
                coded: false,
            })

            const CROMIS_STORE_MODULE_NAME = 'cromis-levels'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, levelsStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-levels/list')
                        .then(response => {
                            if(response.data.levels.length > 0){
                                levels.value = response.data.levels[0]

                                selections.value.push({
                                    id: levels.value.id,
                                    level: levels.value.name.toLowerCase(),
                                    model: null,
                                    label: levels.value.name,
                                    coded: levels.value.coded,
                                    child: levels.value.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                                    locations: [],
                                })

                                let list = levels.value.children

                                while(list.length > 0){
                                    selections.value.push({
                                        id: list[0].id,
                                        level: list[0].name.toLowerCase(),
                                        model: null,
                                        label: list[0].name,
                                        coded: list[0].coded,
                                        child: list[0].children > 0? list[0].children[0].name.toLowerCase() : null,
                                        locations: [],
                                    })

                                    list = list[0].children
                                }

                                level.value.level_id = selections.value[selections.value.length - 1].id
                            }
                        })
                        .catch(error => {
                            console.log('got levels error', error)
                        })
            })

            const handleCreate = async () => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                await store.dispatch('cromis-location/create', location.value)
                    .then(response => {
                        refetch()
                        saving.value = false
                        resetForm()

                        context.root.$swal({
                            icon: 'success',
                            text: `${selections.value[tabIndex.value].label} ${response.data.name} has been saved successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                        saving.value = false
                        resetForm()
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const handleUpdate = async (item) => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                await store.dispatch('cromis-location/update', { id: item.id, data: item })
                    .then(response => {
                        refetch()
                        saving.value = false

                        context.root.$swal({
                            icon: 'success',
                            text: `Changes to ${selections.value[tabIndex.value].label} ${response.data.name} has been made successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })
                        resetForm()
                    })
                    .catch(error => {
                        saving.value = false
                        resetForm()
                        console.log(error)
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                await store.dispatch('cromis-levels/remove', id)
                            .then(response => {
                                refetch()

                                context.root.$swal({
                                    icon: 'info',
                                    title: 'System Info',
                                    text: 'Location was deleted successfully',
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-info',
                                    },
                                    buttonsStyling: true,
                                })
                            })
                            .catch(error => {
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    showConfirmButton: false,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: false,
                                })
                            })
            }

            return {
                saving,
                serverErrors,
                handleCreate,
                handleUpdate,
                remove,
                
                variants,
                selections,
                levels,
                level,
                loading,
                required,
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
